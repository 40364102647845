@import "nodeca.core/client/common/_variables"


.dialogs-create__user-nick-group
  align-items: flex-end
  display none

.dialogs-create__user-nick-label
  white-space nowrap
  margin-right .6em

// Hack to fix bottom align in FF
.dialogs-create__user-nick-col
  flex 1 1 100%
  .twitter-typeahead
    display block !important

// States

.dialogs-create-editor__m-with-nick
  .mdedit-header__caption
    display none
  // Hack - hide collepser for consistent look, when
  // nick field exists
  .mdedit__collapser
    display none
  .dialogs-create__user-nick-group
    display flex

.dialogs-create-editor__m-with-nick.mdedit__m-collapsed
  .mdedit-header__caption
    display block
