@import "nodeca.core/client/common/_variables"

$default-margin = 4rem

.user-media__container
  position relative

.user-media__content
  display flex
  align-items center
  justify-content center
  min-height 200px

  margin 0 $default-margin 1.5rem
  background-color $body-bg-secondary
  border-radius 2px

.user-media__content.user-media__m-binary
  min-height 250px

.user-media__image-container
  flex 0 1 auto
  border-radius inherit
  overflow hidden
.user-media__image-outer
  display block
  max-width 100%
  position relative
.user-media__image-spacer
  display block
.user-media__image
  position absolute
  max-width 100%

.user-media__video
  flex 1 1 auto
  border-radius inherit
  overflow hidden

.user-media__binary
  flex 0 1 auto
  margin 0 3rem
  font-size $font-size-sm
  text-align center
  shortable()

.user-media__binary-icon
  display block
  font-size 6rem
  line-height 1.1em
  color $link-color + 70%
  margin-bottom .5rem

.user-media__prev
.user-media__next
  position absolute
  top 0
  bottom 0
  min-width $default-margin
  color $body-color-secondary
  top 50px    // Add bottom margin, so user won't accidentally click it
  bottom 50px // when trying to access menu
  &:hover
    text-decoration none
    color $body-color-secondary - 20%
  &:focus
    outline none
.user-media__prev
  left 0
  width 29%
.user-media__next
  right 0
  width 39%

// Should not overlap video
.user-media__m-video
  .user-media__prev
  .user-media__next
    max-width $default-margin

// Should not completely overlap files
.user-media__m-binary
  .user-media__prev
  .user-media__next
    max-width 20%

.user-media__prev-icon
.user-media__next-icon
  position absolute
  top 50%
  font-size 2rem
  margin-top -1rem
  line-height 1em
.user-media__prev-icon
  left 0
.user-media__next-icon
  right 0

.user-media__m-disabled
  opacity .4
  &.user-media__prev:hover
  &.user-media__next:hover
    color $body-color-secondary

@media (max-width: $grid-breakpoints.sm - 1)
  .user-media__content
    margin-left 0
    margin-right 0
    min-height 150px
  .user-media__content.user-media__m-binary
    min-height 200px

  .user-media__prev
  .user-media__next
    color $body-color-secondary + 20%
    text-shadow 0 0 1px ($body-color-secondary - 60%)
    &:hover
      color $body-color-secondary + 40%

  .user-media__prev-icon
    left .7rem
  .user-media__next-icon
    right .7rem

  .user-media__m-binary
    .user-media__prev
    .user-media__next
      text-shadow none

  .user-media__binary-icon
    font-size 4rem

.user-media__controls
  position absolute
  // adjustment for _content margin, can't put menu in
  //_content itself because it should be on top of prev/next
  right $default-margin + 1rem
  left auto
  bottom 1rem

.user-media__control-item > .btn
  color white
  background-color rgba(0, 0, 0, 0.3)
  &:hover
    background-color rgba(0, 0, 0, 0.4)


////////////////////////////////////////////////////////////////////////////////

.user-media-root__restore
  margin 0 auto
  position fixed
  bottom 0px
  left 45%
  padding 10px 20px
  background #ffffff
  z-index 30
  border 1px solid #000000


////////////////////////////////////////////////////////////////////////////////
// Comments

.user-media__footer
  list-style none
  margin 0 auto
  padding 0
  max-width 500px

$avatar-size = 30px

.user-media-comment
.user-media-comment__placeholder
  min-height $avatar-size
  margin-bottom 1.5rem

.user-media-comment__placeholder-link
  display inline-block
  height $avatar-size
  line-height $avatar-size

.user-media-comment__aside
  float left
  margin-top .125rem

.user-media-comment__userpic-img
  display inline-block
  width $avatar-size
  height $avatar-size
  border-radius 2px
  box-shadow 0 0 1px $body-color-secondary

.user-media-comment__inner
  margin-right 0px
  margin-left $avatar-size + 16px

.user-media-comment__meta
  display flex
  font-size $font-size-sm
  line-height 1.1em
  color $body-color-secondary
  margin-bottom .1rem

.user-media-comment__author
  flex 1 1 auto
  width 100%
  shortable()
.user-media-comment__author-link
  color $body-color-secondary

.user-media-comment__ts
  flex 1 1 auto
  white-space nowrap
  margin-left 1rem
.user-media-comment__controls
  flex 1 1 auto
.user-media-comment__dropdown
  padding-left 1rem
  color $body-color-secondary

.user-media-comment__content
  line-height 1.25em
