@import "nodeca.core/client/common/_variables"

#member-infractions-info
  margin-top -.5rem

.member-infractions__table
  font-size $font-size-sm
  min-width 540px // for horizontal responsive scroll

  th
    font-weight normal
    color $body-color-secondary
  th
  td
  tbody
    border 0 !important

.member-infractions-item__m-expired
  opacity 0.6

.member-infractions-item__m-deleted
  opacity 0.6
  .member-infractions-item__reason
  .member-infractions-item__points
    text-decoration line-through

.member-infractions-item__m-highlight
  background-color $state-warning-bg
