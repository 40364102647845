@import "nodeca.core/client/common/_variables"

.btn + .btn
  margin-left .4rem

.btn-square
  padding-left 0 !important
  padding-right 0 !important
  min-width 2.375em // 38px

.btn-sm.btn-square
  min-width 2.0em // 28px

.btn-xs.btn-square
  min-width 1.917em // 23px


.dropdown-toggle.btn-square:after
  margin-left 0

.dropdown-toggle.icon:after
  border none !important
  width 0
  margin 0
