// Forum `index` styles.
//

@import "nodeca.core/client/common/_variables"

// Override breadcrumbs layout to add inline button
.forum-index-root .breadcrumbs
  display inline

.breadcrumbs-controls
  display inline-block
  vertical-align middle
  margin-top -3px

//
// Category block
//

.forum-category
  margin-bottom 1.5rem

.forum-category__head
  transition opacity .3s ease
  margin-bottom 1rem
.no-animation .forum-category__head
  transition none

.forum-category__title
  font-weight normal
  font-size $h4-font-size
  line-height 1.6em
  margin 0
  shortable()

a.forum-category__link
  text-decoration none

a.forum-category__collapser
  transition opacity .3s ease
  margin-left .5em
  opacity 0

//
// Transitions & states
//
.forum-category__title:hover .forum-category__collapser
  opacity 1

.collapsed .forum-category__head
  opacity .2

.collapsed:hover .forum-category__head
  opacity .7

// Show block with proper icon, depending on state
.collapsed .forum-category__collapser.icon-collapse
.forum-category__collapser.icon-expand
  display none

.collapsed .forum-category__collapser.icon-expand
  display inline-block
