// timeline block with page request stats

@import "nodeca.core/client/common/_variables"

.debug-timeline
  cursor pointer
  & > .debug-timeline__scope
    margin-left 0
    padding-left 0

.debug-timeline__scope
  list-style none
  padding-left 1rem

.debug-timeline__period
  color $body-color

.debug-timeline__m-hidden
  display none
  background-color $body-bg-secondary
  .debug-timeline__period
    color $body-color-secondary

.debug-timeline__m-show-hidden
  .debug-timeline__m-hidden
    display block
