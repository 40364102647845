@import "nodeca.core/client/common/_variables"

.abuse-report-dlg__loading-placeholder
  padding 2rem
  font-size 4rem
  line-height 1.1em
  text-align center
  background-color $body-bg-secondary
  color $body-color-secondary + 50%
  border-radius 4px
  margin-bottom 2rem
