@import "nodeca.core/client/common/_variables"

.blogs-tag-edit-list
  background-color $body-bg-secondary
  color $body-color-secondary
  cursor pointer
  padding .25rem .375rem
  font-size $font-size-sm
  shortable()

.blogs-tag-edit-list__item + .blogs-tag-edit-list__item
  &:before
    content ', '
