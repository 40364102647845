@import "nodeca.core/client/common/_variables"

.media-select-dlg__no-medias
  margin-top 1rem
  display none

.no-medias .media-select-dlg__no-medias
  display block

.no-more-medias .media-select-dlg__more-medias
  display none

.media-select-dlg__actions
  margin 1rem 0
