// Blog entry
//

@import "nodeca.core/client/common/_variables"

$aside-size = '$$ N.config.users.avatars.resize.sm.height $$'px

.blogs-entry-page__entry
  padding-bottom 1.5rem
  margin-bottom 1.5rem
  border-bottom 1px solid $decorator-color

.blogs-entry-page__m-deleted
.blogs-entry-page__m-deleted-hard
  .blogs-entry-page__reply
    display none

  .blog-entry__title-text
    text-decoration line-through

  .blog-entry__edit
    display none

  .blog-comment__reply
  .blog-comment__votes
  .blog-comment__vote-up
  .blog-comment__vote-down
  .blog-comment__bookmark
    display none

.blogs-entry-page__comment-header
  font-size $font-size-lg
  margin-bottom 1rem

.blogs-entry-page__comment-count:after
  content ' (' attr(data-count) '):'


.blogs-sole
  .blog-entry__title
    font-size $h4-font-size
