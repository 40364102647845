// Attachment generic classes
//

@import "nodeca.core/client/common/_variables"

.attach-img
  background-color $body-bg-secondary

.attach__m-md, .attach__m-orig
  min-width 20px
  min-height 20px
  max-width 100%
  display inline-block
  border-radius 2px
  position relative

  & > img
    max-width 100%
    border-radius 2px

.attach__spacer + img
  position absolute
  top 0
  left 0

// Link to the original image
a.attach__link-control
  display block
  transition .2s ease .1s
  position absolute
  bottom 3px
  right 3px
  opacity 0
  height 30px
  line-height 30px
  width 30px
  text-align center
  color white
  background-color rgba(0, 0, 0, .3)
  border-radius 3px


.attach:hover .attach__link-control
  opacity 1

.attach__spacer
  display block
