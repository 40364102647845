@import "nodeca.core/client/common/_variables"

// if subforums exist - add space before control bar
.forum-section-root .forum-sections
  margin-bottom 30px

.forum-section__notopics
  margin-top 10px
  text-align center

// display subsections only if first page is visible
.forum-section-root .forum-sections
  display none

.forum-section-root__m-first-page .forum-sections
  display block


.forum-topicline__m-deleted
.forum-topicline__m-deleted-hard
  .forum-topicline__bookmarked-marker
    display none

.forum-section-root__m-no-bookmarks
  .forum-topicline__bookmarked-marker
    display none

.forum-section__loading-prev
.forum-section__loading-next
  padding 2rem
  font-size 4rem
  line-height 1.1em
  text-align center
  background-color $body-bg-secondary
  color $body-color-secondary + 50%
  border-radius 4px
  margin-bottom 2rem
