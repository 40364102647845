@import "nodeca.core/client/common/_variables"

$member-list-width = $grid-breakpoints['sm']

.clubs-sole-members__tabs
  justify-content center

.clubs-sole-members__content
  max-width $member-list-width
  margin auto

.clubs-sole-members__list
  padding 1.25rem
  margin 0
  border 1px solid rgba(0, 0, 0, 0.125)
  border-radius 0.25rem

.clubs-sole-members__list-item
  display flex
  align-items center

.clubs-sole-members__member
  flex 1 1 0%
  padding 0.25em 0
  display inline-block
  shortable()

.clubs-sole-members__avatar
  width 16px
  height 16px
  margin 0px 5px
  border-radius 2px

.clubs-sole-members__action
  background-color transparent
  color $body-color-secondary

  &:hover
  &:focus
  &:active
    color $body-color-secondary - 20%
    background-color $body-bg-secondary

.clubs-sole-members__form
  margin-bottom 1rem

.clubs-sole-members__input-group
  display flex

  & > .twitter-typeahead
    flex 1 1 0%
    width auto

@media (max-width: $member-list-width - 1)
  .clubs-sole-members__list
    padding 0
    border 0
