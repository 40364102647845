@import "nodeca.core/client/common/_variables"

.dialog-list-filter
  margin-top .5rem !important
  color $body-color-secondary
  line-height 1.2em

.dialog-list__loading-prev
.dialog-list__loading-next
  padding 2rem
  font-size 4rem
  line-height 1.1em
  text-align center
  background-color $body-bg-secondary
  color $body-color-secondary + 50%
  border-radius 4px
  margin-bottom 2rem
