.visible-deleted-block
  display none !important

.deleted
  &.visible-deleted-block
  .visible-deleted-block
    display block !important

  &.visible-deleted-inline-block
  .visible-deleted-inline-block
  display inline-block !important

  &.visible-deleted-inline
  .visible-deleted-inline
    display inline !important

  &.hidden-deleted
  .hidden-deleted
    display none !important
