@import "nodeca.core/client/common/_variables"

// Hide default caret from .dropdown-toggle
.nav-search
  .nav-link.dropdown-toggle:after
    display none

.nav-search__m-narrow
  display none

.nav-search__form
  display none
  background-color white
  border-radius .25rem

.nav-search__go
.nav-search__input
.nav-search__select
.nav-search__close
  background-color transparent
  border-color transparent !important

.nav-search__input
  &:focus
    box-shadow none

  &::placeholder
    color $body-color-secondary

.nav-search__go
  color $body-color-secondary

.nav-search__select
  color $link-color
  padding-left .5rem
  padding-right .5rem

.nav-search__close
  color $body-color-secondary

.nav-search__input
  padding-left 0


@media (max-width: $grid-breakpoints.md - 1)
  .nav-search__m-wide
    display none
  .nav-search__m-narrow
    display block

@media (min-width: $grid-breakpoints.md)
  .nav-search-on
    .navbar__m-hide-on-search
    .page-progress
      display none
    .nav-search__m-wide
      flex 1 1 0
      min-width 0
      .nav-search__btn
        display none
      .nav-search__form
        display block

    .navbar__right
      margin-left unset
      flex-grow 1

  // Should not hide progress on alternate toolbar
  .navbar-alt
    .page-progress
      display flex
