@import "nodeca.core/client/common/_variables"

.page-progress
  flex-direction row

.page-progress__item + .page-progress__item
  margin-left 0 !important

.page-progress__button-first
  padding-left .8rem !important
  padding-right .5rem !important

.page-progress__button-last
  padding-left .5rem !important
  padding-right .8rem !important
  margin-right .3rem

.content-top .page-progress__m-position-start .page-progress__button-first,
.content-bottom .page-progress__m-position-near-end .page-progress__button-last,
.page-progress__m-position-end .page-progress__button-last
  opacity .4
  cursor default
  &:hover
    color $navbar-dark-color

.page-progress__info
  display inline-block
  min-width 6rem

.page-progress__label
  display inline-block
  width 100%
  text-align center

.page-progress__label-current
  color $navbar-dark-hover-color

.page-progress__label-total
  &:before
    content '/'
    margin-left .2em
    margin-right .2em

.page-progress__bar
  position absolute
  bottom .25rem
  left 0
  right 0
  background-color $navbar-dark-color

.page-progress__bar-fill
  background-color $navbar-dark-hover-color
  height .0625rem
  transition width .3s


.page-progress__menu
  min-width 170px
  padding .5rem .7rem .7rem
  position absolute !important

//
// Highlight on hover
//
.page-progress__button-first
.page-progress__button-last
.page-progress__info
  &:after
    left 0 !important
    right 0 !important
    // Override bootstrap caret
    margin-left 0
    border none

.page-progress__button-first
  &:after
    left .3rem !important
.page-progress__button-last
  &:after
    right .3rem !important
