// Popup with link for cnotent restore-notice
//

@import "nodeca.core/client/common/_variables"

.restore-notice
  position fixed
  left 50%
  top 200px
  max-width 100%

.restore-notice__inner
a.restore-notice__inner
  position relative
  left -50%
  margin 0 20px
  display block
  shortable()

  background-color #666
  border-radius 100px
  color #fff
  padding 10px 20px
  font-weight bold
  text-decoration none
  box-shadow 0 0 2px 2px #fff
