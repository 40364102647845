@import "nodeca.core/client/common/_variables"

.blogs-sole-title-user
  &,&:hover,&:focus,&:active
    color inherit

.blogs-sole-title-user__avatar
  width 20px
  height 20px
  border-radius 3px
  margin-right 10px
  box-shadow 0 0 1px $body-color-secondary
