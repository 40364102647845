@import "nodeca.core/client/common/_variables"

.market-search-buy .market-search-form-buy
  margin-bottom 2rem

.market-search-buy__sections-order-block
  margin-bottom 2rem

.market-search-buy__head
  display flex

.market-search-buy__title
  flex 1 1 0%

.market-search-buy__sections-order-block
  display flex

.market-search-buy__sections
  flex 1 1 0%

.market-search-buy__select-order
  width auto
  height 3rem

$max-section-indent = 5

.market-search-buy__section
  margin-left 20px * ($max-section-indent - 1)

for $level in (0..$max-section-indent - 2)
  .market-search-buy__section-level-{$level}
    margin-left 20px * $level

.market-search-buy__section-active
  font-weight bold

.market-search-buy__section-title
  color $body-color

.market-search-buy__section-count
  color $body-color-secondary
  vertical-align sub
  font-size $font-size-xs

.market-search-buy__item-list
  margin 0
  padding 0
  list-style none

.market-search-buy__error
  margin-top 1rem

.market-search-buy__loading-next
  padding 2rem
  font-size 4rem
  line-height 1.1em
  text-align center
  background-color $body-bg-secondary
  color $body-color-secondary + 50%
  border-radius 4px
  margin-bottom 2rem
  margin-top 1rem
  clear both
