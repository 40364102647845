@import "nodeca.core/client/common/_variables"

.user-settings__tabs
  justify-content center

.user-settings__tab-content
  padding 1rem
  background-color $body-bg-secondary
  border-radius 4px

.user-settings__m-narrow
  max-width 400px
  margin 0 auto
