@import "nodeca.core/client/common/_variables"

.blogs-sole .page-head__title
  color $body-color-secondary

.blogs-sole__author-link
  color inherit

.blogs-sole__head
  display flex
  padding-bottom .75rem
  border-bottom 1px solid $decorator-color

.blogs-sole__head-content
  flex 1 1 100%

.blogs-sole__meta .blogs-tag-list
  font-size $font-size-sm

$userpic-size = 30px

.blogs-sole__userpic
  width $userpic-size
  height $userpic-size
  margin-right .7rem
  margin-top 4px
  border-radius 3px
  box-shadow 0 0 1px $body-color-secondary

.blogs-sole__head-m-tagged
  .blogs-sole__userpic
    width 50px
    height 50px
    margin-top 2px
  .page-head__title
    font-size $h4-font-size

.blogs-sole__loading-prev
.blogs-sole__loading-next
  padding 2rem
  font-size 4rem
  line-height 1.1em
  text-align center
  background-color $body-bg-secondary
  color $body-color-secondary + 50%
  border-radius 4px
  margin-bottom 2rem

.blogs-sole
  .blog-entry__m-deleted
  .blog-entry__m-hb
  .blog-entry__m-deleted-hard
  //.blog-entry__m-ignored
    background-color $deleted-bg
