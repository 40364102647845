@import "nodeca.core/client/common/_variables"

.markup-selection-menu__btn
  display inline-block
  padding .375rem .75rem
  color $white !important
  background-color $gray-900
  border-radius .25rem
  border 1px solid transparent
  text-decoration none !important
  transition: 0.15s ease-in-out
  &:hover
    background-color #888
