@import "nodeca.core/client/common/_variables"

.markup .image__m-hidden
  display inline-block
  cursor pointer
  background-color $body-bg-secondary
  border 1px solid #ccc
  width '$$ N.config.users.uploads.resize.sm.max_width || N.config.users.uploads.resize.sm.width $$'px

  .image__spacer
    padding-bottom '$$ ((N.config.users.uploads.resize.sm.max_height || N.config.users.uploads.resize.sm.height) / (N.config.users.uploads.resize.sm.max_width || N.config.users.uploads.resize.sm.width) * 100).toFixed(4) $$'%
