@import "nodeca.core/client/common/_variables"


.member-block__bookmarks-info
  //font-size $font-size-sm
  line-height 1.5em
  list-style none
  margin 0
  padding 0

.member-block__bookmarks-info-item
  margin-bottom .5em
  shortable()
