
.icon-search:before { content: '\e800'; } /* '' */
.icon-link:before { content: '\e801'; } /* '' */
.icon-attach:before { content: '\e802'; } /* '' */
.icon-bookmark:before { content: '\e803'; } /* '' */
.icon-edit:before { content: '\e804'; } /* '' */
.icon-download:before { content: '\e805'; } /* '' */
.icon-x:before { content: '\e806'; } /* '' */
.icon-report:before { content: '\e807'; } /* '' */
.icon-history:before { content: '\e808'; } /* '' */
.icon-ref-outer:before { content: '\e809'; } /* '' */
.icon-expand-alt:before { content: '\e80a'; } /* '' */
.icon-collapse-alt:before { content: '\e80b'; } /* '' */
.icon-comment:before { content: '\e80c'; } /* '' */
.icon-ref-local:before { content: '\e80d'; } /* '' */
.icon-infraction:before { content: '\e80e'; } /* '' */
.icon-editor-full:before { content: '\e80f'; } /* '' */
.icon-plus:before { content: '\e810'; } /* '' */
.icon-editor-small:before { content: '\e811'; } /* '' */
.icon-video:before { content: '\e813'; } /* '' */
.icon-binary:before { content: '\e816'; } /* '' */
.icon-camera:before { content: '\e817'; } /* '' */
.icon-dropdown-local:before { content: '\e818'; } /* '' */
.icon-right-open:before { content: '\e819'; } /* '' */
.icon-help:before { content: '\e81a'; } /* '' */
.icon-info:before { content: '\e81b'; } /* '' */
.icon-list-bullet:before { content: '\e81c'; } /* '' */
.icon-list-numbered:before { content: '\e81d'; } /* '' */
.icon-quote:before { content: '\e81e'; } /* '' */
.icon-bold:before { content: '\e81f'; } /* '' */
.icon-italic:before { content: '\e820'; } /* '' */
.icon-header:before { content: '\e821'; } /* '' */
.icon-vote-up:before { content: '\e822'; } /* '' */
.icon-to-top:before { content: '\e823'; } /* '' */
.icon-picture:before { content: '\e824'; } /* '' */
.icon-level-up:before { content: '\e825'; } /* '' */
.icon-closed:before { content: '\e826'; } /* '' */
.icon-mail:before { content: '\e827'; } /* '' */
.icon-views:before { content: '\e828'; } /* '' */
.icon-more:before { content: '\e829'; } /* '' */
.icon-pin:before { content: '\e82a'; } /* '' */
.icon-clock:before { content: '\e82b'; } /* '' */
.icon-ignore:before { content: '\e82c'; } /* '' */
.icon-to-bottom:before { content: '\e82d'; } /* '' */
.icon-menu:before { content: '\e82e'; } /* '' */
.icon-chevron-big-left:before { content: '\e82f'; } /* '' */
.icon-chevron-big-right:before { content: '\e830'; } /* '' */
.icon-loading-placeholder:before { content: '\e831'; } /* '' */
.icon-location:before { content: '\e832'; } /* '' */
.icon-login:before { content: '\e833'; } /* '' */
.icon-preview:before { content: '\e834'; } /* '' */
.icon-delivery:before { content: '\e835'; } /* '' */
.icon-interchange:before { content: '\e836'; } /* '' */
.icon-track-muted:before { content: '\e837'; } /* '' */
.icon-track-normal:before { content: '\e838'; } /* '' */
.icon-track-tracking:before { content: '\e839'; } /* '' */
.icon-track-watching:before { content: '\e83a'; } /* '' */
.icon-check-single:before { content: '\e83b'; } /* '' */
.icon-posts:before { content: '\e83f'; } /* '' */
.icon-replies:before { content: '\e840'; } /* '' */
.icon-reply:before { content: '\e841'; } /* '' */
.icon-reply-for:before { content: '\e842'; } /* '' */
.icon-vote-down:before { content: '\e844'; } /* '' */
.icon-expand:before { content: '\e845'; } /* '' */
.icon-collapse:before { content: '\e846'; } /* '' */
.icon-dropdown:before { content: '\e847'; } /* '' */
.icon-check:before { content: '\e848'; } /* '' */
.icon-threads:before { content: '\e849'; } /* '' */
.icon-arrow-subsections:before { content: '\e84a'; } /* '' */
.icon-editor-narrow:before { content: '\f100'; } /* '' */
.icon-editor-unnarrow:before { content: '\f101'; } /* '' */
.icon-emoji:before { content: '\f118'; } /* '' */
.icon-my-location:before { content: '\f124'; } /* '' */
.icon-sliders:before { content: '\f1de'; } /* '' */
