@import "nodeca.core/client/common/_variables"

.market-all-wish .market-search-form-wish
  margin-bottom 2rem

.market-all-wish__head
  display flex

.market-all-wish__title
  flex 1 1 0%

.market-all-wish__item-list
  margin 0
  padding 0
  list-style none

.market-all-wish__loading-prev
.market-all-wish__loading-next
  padding 2rem
  font-size 4rem
  line-height 1.1em
  text-align center
  background-color $body-bg-secondary
  color $body-color-secondary + 50%
  border-radius 4px
  margin-bottom 2rem
  margin-top 1rem
  clear both
