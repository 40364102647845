@import "nodeca.core/client/common/_variables"

.market-item-wish__head
  display flex

.market-item-wish__title
  margin-bottom .25rem

.market-item-wish__title-toolbar
  flex 1 1 0%

.market-item-wish__edit
  font-size 1.25rem
  line-height 1.1em
  color $body-color-secondary
  vertical-align middle
  padding .125rem .5rem

.market-item-wish__closed-mark
  display none

.market-item-wish__m-closed
  .market-item-wish__closed-mark
    display inline-block

  .market-item-wish__contact
    display none

.market-item-wish__m-deleted
.market-item-wish__m-deleted-hard
  .market-item-wish__title-text
    text-decoration line-through

  .market-item-wish__contact
  .market-item-wish__bookmark
    display none

.market-item-wish__renew-btn
  float right

.market-item-wish__info
  color $body-color-secondary
  margin 0
  padding 0
  list-style none

.market-item-wish__info-item
  display inline

.market-item-wish__user-link
.market-item-wish__section-link
  color $body-color-secondary

.market-item-wish__info-item + .market-item-wish__info-item
  &:before
    content '|'
    margin-left .5rem
    margin-right .5rem
    opacity .7

.market-item-wish__details
  margin-bottom 1rem
  display table

.market-item-wish__details-item
  display table-row

.market-item-wish__details-key
  display table-cell
  color $body-color-secondary
  text-align right
  vertical-align top
  padding-right .75rem

.market-item-wish__details-value
  display table-cell
  vertical-align top

.market-item-wish__location-link
  // make link the same color as normal text
  color inherit

.market-item-wish__controls
  margin-bottom 2rem

.market-item-wish__controls-blk
  display flex
  align-items center
  margin 0 0 .5rem 0
  padding 0
  list-style none

.market-item-wish__control-item
  color $body-color-secondary
  display inline-block

.market-item-wish__action
  background-color transparent
  color $body-color-secondary

  &:hover
  &:focus
  &:active
    color $body-color-secondary - 20%
    background-color $body-bg-secondary

.market-item-wish__infraction .icon
  color $brand-danger
.market-item-wish__infraction-m-warning .icon
  color $brand-warning

.market-item-wish__bookmark-remove
  display none

// override .btn+.btn margin
.market-item-wish__bookmark-remove.market-item-wish__bookmark-remove
  margin 0

.market-item-wish__m-bookmarked
  .market-item-wish__bookmark-remove
    display inline-block
    opacity 1
    .icon
      color $brand-success
  .market-item-wish__bookmark-add
    display none

.market-item-wish__bookmarks-count
  font-size $font-size-xs
  color $body-color-secondary

.market-item-wish__bookmarks-count:after
  content attr(data-bm-count)
  margin-left .3em

.market-item-wish__bookmarks-count[data-bm-count="0"]:after
  display none

.market-item-wish__ts
  margin-right .75rem

.market-item-wish__views
  margin-left .75rem

//
// Responses block
//
.market-item-wish-responses
  margin-bottom 2rem

.market-item-wish-responses__header
  margin-bottom 1rem

.market-item-wish-responses__m-empty
  color $body-color-secondary

.market-item-wish-responses__item-list
  margin 0
  padding 0
  list-style none

.market-item-wish-response
  margin-bottom 0.5rem

.market-item-wish-response__link
  color $body-color + 30%
  font-size $font-size-sm

.market-item-wish-response__avatar
  display inline-block
  width 20px
  height 20px
  margin-right .5rem
  border-radius 2px
  box-shadow 0 0 1px $body-color-secondary
