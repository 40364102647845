@import "nodeca.core/client/common/_variables"

$aside-size = '$$ N.config.users.avatars.resize.sm.height $$'px

.clubs-sole__head
  display flex
  padding-bottom 1rem
  margin-bottom 1.5rem
  border-bottom 1px solid $decorator-color
  line-height 1.25em

.clubs-sole__meta
  flex 1 1 0%

.clubs-sole__aside
  flex 0 0 $aside-size + 10px

.clubs-sole__avatar
  width $aside-size
  height $aside-size
  border-radius 3px
  box-shadow 0 0 1px $body-color-secondary

.clubs-sole__meta-top
  display flex
  align-items center

.clubs-sole__meta-title-owners
  flex 1 1 0%

.clubs-sole__title
  color $body-color-secondary
  font-size $font-size-lg

.clubs-sole-root__m-deleted
  .clubs-sole__title-text
    text-decoration line-through

.clubs-sole__edit
  font-size 1.25rem
  line-height 1.1em
  color $body-color-secondary
  vertical-align middle
  padding .125rem .5rem

.clubs-sole__owners
  color $body-color-secondary
  font-size $font-size-xs

.clubs-sole__owner-list
  margin 0
  padding 0
  list-style none
  margin-left 0.2rem
  display inline

.clubs-sole__owner-list-item
  display inline

.clubs-sole__owner-list-item + .clubs-sole__owner-list-item
  &:before
    content ', '

.clubs-sole__owner-list-link
  color $body-color-secondary

.clubs-sole__location
  margin-right 1rem

.clubs-sole__description
.clubs-sole__members
  margin-top 1rem

.clubs-sole__members
  font-size $font-size-xs

.clubs-sole__notopics
  margin-top 10px
  text-align center

.clubs-topicline__m-deleted
.clubs-topicline__m-deleted-hard
  .clubs-topicline__bookmarked-marker
    display none

.clubs-sole__loading-prev
.clubs-sole__loading-next
  padding 2rem
  font-size 4rem
  line-height 1.1em
  text-align center
  background-color $body-bg-secondary
  color $body-color-secondary + 50%
  border-radius 4px
  margin-bottom 2rem
