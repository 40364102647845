@import "nodeca.core/client/common/_variables"

.market-item-buy-edit__currency.market-item-buy-edit__currency
  flex 0 0 4em

.market-item-buy-edit__textarea
  min-height 6rem !important

.market-item-buy-edit__label
  color $body-color
  padding-left 0

.market-item-buy__preview-controls
  display flex
  align-items baseline
  justify-content space-between

.market-item-buy__preview-btn
  padding 0.0625rem 0.4375rem .125rem
.market-item-buy__preview
  border 1px dashed $input-border-color
  padding .5rem
  border-radius 4px
  overflow-y auto
  margin-right 0

  .quote__author-avatar
    background-color $decorator-color
