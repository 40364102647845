@import "nodeca.core/client/common/_variables"

.market-list-item-wish
  display flex
  margin-bottom 1rem

.market-list-item-wish__info
  flex 1 1 auto
  min-width 0

.market-list-item-wish__title
  font-size $font-size-lg
  line-height 1.125em
  margin-bottom .125rem

.market-list-item-wish__location
  color $body-color-secondary

.market-list-item-wish__location-link
  color inherit

.market-list-item-wish__user-link
.market-list-item-wish__section-link
  color $body-color-secondary
  color inherit

.market-list-item-wish__details
  color $body-color-secondary
  shortable()

.market-list-item-wish__details-item + .market-list-item-wish__details-item
  &:before
    content '|'
    margin-left .5rem
    margin-right .5rem
    opacity .5

.market-list-item-wish__meta-del-by
  color $body-color-secondary

.market-list-item-wish__meta-deleted
.market-list-item-wish__meta-hellbanned
  color $body-color-secondary
  shortable()

.market-list-item-wish__meta-hellbanned
  display none

.market-list-item-wish__bookmarked-marker
  color $brand-success
  display none

.market-list-item-wish__deleted-marker
  display none

.market-list-item-wish__m-bookmarked
  .market-list-item-wish__bookmarked-marker
    display inline-block

// only hide hb items on section and search pages,
// show them items on user pages as regular
.market-section-wish__item-list
.market-search-wish__item-list
  .market-list-item-wish__m-hellbanned
    .market-list-item-wish__meta-hellbanned
      display block

.market-list-item-wish__m-deleted
.market-list-item-wish__m-deleted-hard
  .market-list-item-wish__deleted-marker
    display inline-block

// only hide hb items on section and search pages,
// show them items on user pages as regular
.market-section-wish__item-list
.market-search-wish__item-list
  .market-list-item-wish__m-hellbanned
    background-color $deleted-bg
    opacity 0.7
    &:hover
      opacity 1
    .market-list-item-wish__location
    .market-list-item-wish__details
      display none

.market-list-item-wish__m-deleted
.market-list-item-wish__m-deleted-hard
  background-color $deleted-bg
  opacity 0.7
  &:hover
    opacity 1
  .market-list-item-wish__location
  .market-list-item-wish__details
    display none

@keyframes market-list-item-wish-flash-animation {
  from  { background: $decorator-color;  }
  to    { background: none; }
}

.market-list-item-wish__m-highlight
  animation market-list-item-wish-flash-animation 1.5s
  .market-list-item-wish__highlight-marker
    display block

.market-list-item-wish__m-selected
  background-color #ffd
