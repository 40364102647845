@import "nodeca.core/client/common/_variables"

.blog-entry
  margin-bottom 2rem

.blog-entry__head
  padding-bottom 0.5rem
  margin-bottom 0.5rem
  background-color white

.blog-entry__title .blog-entry__edit
  font-size 1.25rem
  line-height 1.1em
  color $body-color-secondary
  vertical-align middle
  padding .125rem .5rem

.blog-entry__meta
  color $body-color-secondary
  font-size $font-size-xs

.blog-entry__meta-item
  &:after
    content ', '

  &:last-child:after
    content none

.blog-entry__author
  font-size $font-size-xs
  line-height 2rem

.blog-entry__author-link
  color $body-color-secondary

.blog-entry__avatar
  display inline-block
  width 20px
  height 20px
  margin-right .5rem
  border-radius 2px
  box-shadow 0 0 1px $body-color-secondary

.blog-entry__select
  float right
  min-width 2rem
  text-align center
  margin-bottom 0
  padding-bottom .5rem

.blog-entry__select-cb
  position relative
  vertical-align text-bottom

.blog-entry__content
  margin-right 0px

.blog-entry__content-collapsed
  padding 0 1rem
  font-size $font-size-sm
  color $body-color-secondary

.blog-entry__content-ignored
  display flex
  justify-content space-between
  padding .5rem 1rem
  font-size $font-size-sm
  color $body-color-secondary

.blog-entry__del-by-user
  color inherit

.blog-entry__undelete
  display none

.blog-entry__m-deleted
.blog-entry__m-deleted-hard
  .blog-entry__undelete
    display block

  .blog-entry__delete
  .blog-entry__edit
  .blog-entry__votes
  .blog-entry__vote-up
  .blog-entry__vote-down
  .blog-entry__bookmark
    display none

@keyframes blog-entry-under-cut-fade-in {
  from  { opacity: 0; }
  to    { opacity: 1; }
}

.blog-entry__under-cut
  animation blog-entry-under-cut-fade-in 1s

.blog-entry__read-more
  display none

.blog-entry__m-can-read-more
  .blog-entry__read-more
    display block
    font-size $font-size-sm
    margin-bottom 1rem

.blog-entry__show-ignored
  color inherit
  cursor pointer

.blog-entry__controls-blk
  display flex
  align-items center
  margin 0 0 .5rem 0
  padding 0
  list-style none

.blog-entry__control-item
  color $body-color-secondary
  display inline-block

.blog-entry__action
  background-color transparent
  color $body-color-secondary

  &:hover
  &:focus
  &:active
    color $body-color-secondary - 20%
    background-color $body-bg-secondary

.blog-entry__infraction .icon
  color $brand-danger
.blog-entry__infraction-m-warning .icon
  color $brand-warning

.blog-entry__ignored-mark
  margin-right .5rem
  display none
.blog-entry__m-ignored
  .blog-entry__ignored-mark
    display inline-block

.blog-entry__m-voted-up
  .blog-entry__vote-up
    .blog-entry__action
      opacity 1
    .icon
      color $brand-success

.blog-entry__m-voted-down
  .blog-entry__vote-down
    .blog-entry__action
      opacity 1
    .icon
      color $brand-danger

// override .btn+.btn margin
.blog-entry__bookmark-remove.blog-entry__bookmark-remove
  margin 0

.blog-entry__bookmark-remove
  display none

.blog-entry__m-bookmarked
  .blog-entry__bookmark-remove
    display inline-block
    opacity 1
    .icon
      color $brand-success
  .blog-entry__bookmark-add
    display none

.blog-entry__bookmarks-count
  //font-size $font-size-xs
  color $body-color-secondary

.blog-entry__bookmarks-count:after
  content attr(data-bm-count)
  margin-left .3em

.blog-entry__bookmarks-count[data-bm-count="0"]:after
  display none

.blog-entry__votes
  .blog-entry__action:after
    content attr(data-votes-count)
  .blog-entry__action[data-votes-count="0"]
    display none
  .blog-entry__action[data-votes-count^="+"]
    color $brand-success
    font-weight bold

.blog-entry .blogs-tag-list__category
  font-weight bold

.blog-entry__ts
  margin-right .75rem

.blog-entry__views
  margin-left .75rem
