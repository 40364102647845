// Page header block (under breadcrubmbs): title + description
//

@import "nodeca.core/client/common/_variables"

.page-head
  margin-top 1.5rem // For pages without breadcrumbs
  margin-bottom 1.5rem
  line-height 1.1

  //.page-actions
  //  margin-top -.25rem

.page-head__title
  margin 0
  font-weight normal
  font-size $h3-font-size
  color $brand-primary

.page-head__descr
  font-size $font-size-xs
  color $body-color-secondary
  margin .375rem 0 0 0
