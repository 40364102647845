@import "nodeca.core/client/common/_variables"

//.member-about__dl
//  font-size $font-size-sm

.member-about__dt
  float left
  clear left
  width 100px
  text-align right
  font-weight normal
  color $body-color-secondary

.member-about__dd
  margin: 0 0 0 110px
  padding: 0 0 0.5em 0

// For portrait mobile
/*@media (max-width: 400px - 1)
  .member-about__dt
    float none
    width 100%
    text-align left
  .member-about__dd
    margin: 0*/

.member-about__m-hidden
  display none

@keyframes member-about-expand-animation {
  from  { opacity: 0;  }
  to    { opacity: 1; }
}

.member-about__m-expanded
  .member-about__m-hidden
    display block
    animation member-about-expand-animation 1s
  .member-about__expand
    display none
