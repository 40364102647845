.moderator-info__list
  padding 0

.moderator-info__list-item
  display inline-block
  &:after
    content ','
    margin-right 0.5em
  &:last-child:after
    content ''

.moderator-info__avatar
  width 16px
  height 16px
  margin 0px 3px
  border-radius 2px
