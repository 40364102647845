@import "nodeca.core/client/common/_variables"

$sep-width    = .1875rem
$avatar-size  = 1.875rem
$space        = 1rem

.user-messages-list-item
  display flex
  margin-bottom 1.5rem

.user-messages-list-item__aside
  flex 0 0 0%
  padding ($space / 2) $space ($space / 2) 0
  min-width $avatar-size + $space

.user-messages-list-item__aside-inline
  margin-bottom 1rem
  display none

.user-messages-list-item__avatar-image
  display inline-block
  width  $avatar-size
  height $avatar-size
  border-radius 2px
  box-shadow: 0 0 1px $body-color-secondary

.user-messages-list-item__separator
  flex 0 0 0%
  min-width $sep-width
  border-radius 1rem
  background-color $decorator-aside-color

.user-messages-list-item__content
  flex 1 1 0%
  min-width 0
  padding: ($space / 2) (($avatar-size + $space) * 2 + $sep-width) 0 $space

.user-messages-list-item__message
  //margin-top ($space / 4)
  & > p:first-child
    margin-top -.25rem // Compensate line-height 1.5 rem

  & > p:last-child
    margin-bottom .5rem // Compensate line-height 1.5 rem

.user-messages-list-item__controls
  font-size $font-size-xs
  color $body-color-secondary
  margin 0
  padding 0
  list-style none

.user-messages-list-item__controls-item
  display inline-block
  margin-right: .25rem
  &:last-child
    margin-right: 0

.user-messages-list-item__infraction .icon
  color $brand-danger
.user-messages-list-item__infraction-m-warning .icon
  color $brand-warning


.user-messages-list-item__action
  background-color transparent
  color $body-color-secondary

  &:hover
  &:focus
  &:active
    color $body-color-secondary - 20%
    background-color $body-bg-secondary

// Mirrored layout
.user-messages-list-item__m-self
  .user-messages-list-item__aside
    padding-left $space
    padding-right 0
  .user-messages-list-item__content
    padding: ($space / 2) $space 0 (($avatar-size + $space) * 2 + $sep-width)
  .user-messages-list-item__controls
  .user-messages-list-item__aside-inline
    text-align right

.user-messages-list-item__m-self + .user-messages-list-item__m-self
.user-messages-list-item__m-not-self + .user-messages-list-item__m-not-self
  .user-messages-list-item__avatar
  .user-messages-list-item__avatar-image
    visibility hidden
  .user-messages-list-item__aside-inline
    display none

@media (max-width: $grid-breakpoints.sm - 1)
  .user-messages-list-item__aside-inline
    display block
  .user-messages-list-item__aside
    display none

  .user-messages-list-item__content
    padding-right $sep-width + $space

  .user-messages-list-item__m-self
    .user-messages-list-item__content
      padding-left $sep-width + $space
