@import "nodeca.core/client/common/_variables"

.market-list-item-offer
  margin-bottom 1rem

.market-list-item-offer__title-sm
  font-size 1.25rem
  line-height 1.125em
  margin-bottom .5rem
  width 100%

.market-list-item-offer__content
  display flex

.market-list-item-offer__aside
  margin-right 1rem

.market-list-item-offer__info
  flex 1 1 auto
  min-width 0

.market-list-item-offer__title
  font-size 1.25rem
  line-height 1.125em
  margin-bottom .375rem

.market-list-item-offer__price
  font-size 1.125rem
  line-height 1.125em
  margin-bottom .125rem

.market-list-item-offer__price-original
  color $body-color-secondary
  margin-left .2rem


.market-list-item-offer__location
  color $body-color-secondary

.market-list-item-offer__location-link
  color inherit

.market-list-item-offer__user-link
.market-list-item-offer__section-link
  color inherit

.market-list-item-offer__details
  color $body-color-secondary
  shortable()

.market-list-item-offer__details-item + .market-list-item-offer__details-item
  &:before
    content '|'
    margin-left .5rem
    margin-right .5rem
    opacity .5

.market-list-item-offer__used
  margin-left .3rem
  color $body-color-secondary
  font-size $font-size-xs

.market-list-item-offer__delivery
  margin-left .75rem
  color $body-color-secondary

.market-list-item-offer__interchange
  margin-left .75rem
  color $brand-primary + 70%

.market-list-item-offer__interchange:first-child
  margin-left 0

.market-list-item-offer__meta-del-by
  color $body-color-secondary

.market-list-item-offer__meta-deleted
.market-list-item-offer__meta-hellbanned
  color $body-color-secondary
  shortable()

.market-list-item-offer__meta-hellbanned
  display none

.market-list-item-offer__bookmarked-marker
  color $brand-success
  display none

.market-list-item-offer__deleted-marker
  display none

.market-list-item-offer__m-bookmarked
  .market-list-item-offer__bookmarked-marker
    display inline-block

// only hide hb items on section and search pages,
// show them items on user pages as regular
.market-section-buy__item-list
.market-search-buy__item-list
  .market-list-item-offer__m-hellbanned
    .market-list-item-offer__meta-hellbanned
      display block

.market-list-item-offer__m-deleted
.market-list-item-offer__m-deleted-hard
  .market-list-item-offer__deleted-marker
    display inline-block

// only hide hb items on section and search pages,
// show them items on user pages as regular
.market-section-buy__item-list
.market-search-buy__item-list
  .market-list-item-offer__m-hellbanned
    background-color $deleted-bg
    opacity 0.7
    &:hover
      opacity 1
    .market-list-item-offer__image-link
    .market-list-item-offer__price
    .market-list-item-offer__location
    .market-list-item-offer__details
      display none

.market-list-item-offer__m-deleted
.market-list-item-offer__m-deleted-hard
  background-color $deleted-bg
  opacity 0.7
  &:hover
    opacity 1
  .market-list-item-offer__image-link
  .market-list-item-offer__price
  .market-list-item-offer__location
  .market-list-item-offer__details
    display none

@keyframes market-list-item-offer-flash-animation {
  from  { background: $decorator-color;  }
  to    { background: none; }
}

.market-list-item-offer__m-highlight
  animation market-list-item-offer-flash-animation 1.5s
  .market-list-item-offer__highlight-marker
    display block

.market-list-item-offer__m-selected
  background-color #ffd

//
// Thumbnails
//
$thumb-image-width-full  = '$$ N.config.market.uploads.resize.sm.max_width || N.config.market.uploads.resize.sm.width $$'px
$thumb-image-height-full = '$$ N.config.market.uploads.resize.sm.max_height || N.config.market.uploads.resize.sm.height $$'px

// 136x120 -> 113x100
$down-scale = 1.2
$thumb-image-width  = round($thumb-image-width-full / $down-scale)
$thumb-image-height = round($thumb-image-height-full / $down-scale)
$corner-radius = 3px

// 136x120 -> 85x75
$down-scale-sm = 1.6
$thumb-image-width-sm  = round($thumb-image-width-full / $down-scale-sm)
$thumb-image-height-sm = round($thumb-image-height-full / $down-scale-sm)
$corner-radius-sm = 2px

.market-list-item-offer__image-link
  border-radius $corner-radius
  width $thumb-image-width
  height $thumb-image-height
  box-shadow 0 0 1px ($body-bg-secondary - 10%)
  background-color $body-bg-secondary
  display flex
  align-items center
  justify-content center

.market-list-item-offer__image
  border-radius $corner-radius
  max-width $thumb-image-width
  max-height $thumb-image-height
  width fit-content
  height fit-content

@media (max-width: $grid-breakpoints.sm - 1)
  .market-list-item-offer__image-link
    border-radius $corner-radius-sm
    width $thumb-image-width-sm
    height $thumb-image-height-sm

  .market-list-item-offer__image
    border-radius $corner-radius-sm
    max-width $thumb-image-width-sm
    max-height $thumb-image-height-sm
