@import "nodeca.core/client/common/_variables"

filledNoteBg = #fcf8e3
//filledNoteBg = $brand-warning

.usernote
  width 100%
  border-radius 4px
  background-color filledNoteBg
  border 1px solid filledNoteBg - 20%
  padding 1rem
  margin 0 0 1.5rem 0
  fix-bottom()

.usernote__controls
  margin-right -10px
  margin-top -10px
  opacity .5
  transition opacity .5s ease
  &:hover
    opacity 1
