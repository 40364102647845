@import "nodeca.core/client/common/_variables"

.subscription-dlg__item
  color $body-color
  &:hover
    color $body-color
    text-decoration none

.subscription-dlg__item-heading
  font-size $font-size-lg
  font-weight 500

  .icon
    margin-right .45rem

.subscription-dlg__item-text
  color $body-color-secondary
  font-size $font-size-sm
  line-height 1.3

.list-group-item.active .subscription-dlg__item-text
  color $white

.list-group-item.active:hover .subscription-dlg__item-text
  color $body-color
