@import "nodeca.core/client/common/_variables"

.blog-entry-history-entry
  overflow-wrap break-word
  margin-bottom 2rem

.blog-entry-history-entry + .blog-entry-history-entry
  border-top 1px solid $decorator-color
  padding-top .5rem

.blog-entry-history-entry__meta
  padding 0 .2rem
  margin .5rem 0
  font-size $font-size-sm
  color $body-color-secondary + 20%

.blog-entry-history-entry__icon-task
.blog-entry-history-entry__icon-moderator
  font-weight bold
  color #c97971
  margin-left .375rem
  display none

.blog-entry-history-entry__m-task .blog-entry-history-entry__icon-task
.blog-entry-history-entry__m-moderator .blog-entry-history-entry__icon-moderator
  display inline

.blog-entry-history-entry__meta-ts:after
  content ', '

.blog-entry-history-entry__meta-author-link
  color inherit

.blog-entry-history-entry__title
  font-size $font-size-lg
  margin-bottom .5rem

.blog-entry-history-entry__diff
  margin-bottom .5rem

.blog-entry-history-entry__actions
  color $body-color + 50%
  margin-bottom .5rem

.blog-entry-history-entry__details
  display table

.blog-entry-history-entry__details-item
  display table-row

.blog-entry-history-entry__details-key
  display table-cell
  color $body-color-secondary
  text-align right
  vertical-align top
  padding-right .75rem

.blog-entry-history-entry__details-value
  display table-cell
  vertical-align top
