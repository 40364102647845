@import "nodeca.core/client/common/_variables"

.blog-comment-history-entry
  overflow-wrap break-word
  margin-bottom 2rem

.blog-comment-history-entry + .blog-comment-history-entry
  border-top 1px solid $decorator-color
  padding-top .5rem

.blog-comment-history-entry__meta
  padding 0 .2rem
  margin .5rem 0
  font-size $font-size-sm
  color $body-color-secondary + 20%

.blog-comment-history-entry__icon-task
.blog-comment-history-entry__icon-moderator
  font-weight bold
  color #c97971
  margin-left .375rem
  display none

.blog-comment-history-entry__m-task .blog-comment-history-entry__icon-task
.blog-comment-history-entry__m-moderator .blog-comment-history-entry__icon-moderator
  display inline

.blog-comment-history-entry__meta-ts:after
  content ', '

.blog-comment-history-entry__meta-author-link
  color inherit

.blog-comment-history-entry__title
  font-size $font-size-lg
  margin-bottom .5rem

.blog-comment-history-entry__diff
  margin-bottom .5rem

.blog-comment-history-entry__actions
  color $body-color + 50%
