@import "nodeca.core/client/common/_variables"

.search-form
  background-color $body-bg-alt-secondary
  border-radius .25rem
  margin-bottom 2rem

.search-form__input-container
  padding 1rem 1.25rem .5rem

.search-form__meta
  display block
  font-size $font-size-sm
  margin-top .25rem

.search-form__options-toggle
  color $body-color-secondary !important

.search-form__submit
  background-color white
  color $body-color

.search-form__options-inner
  border-top 1px solid ($body-color-secondary + 60%)
  padding 1rem 1.25rem

//
// Filter under form
//
.search-filter
  display block
  margin-bottom 1.5rem

.search-filter__title
  color $body-color-secondary - 15%
.search-filter__remove
  color $body-color-secondary
  padding 0 .5rem
