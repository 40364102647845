@import "nodeca.core/client/common/_variables"

.clubs-search__head
  display flex

.clubs-search__title
  flex 1 1 0%

.clubs-search__form
  margin-bottom 1.5rem

.clubs-search-sole
  display flex
  margin-bottom 1.5rem

.clubs-search-sole__meta
  flex 1 1 0%
  margin-left 1rem
  display flex
  flex-direction column
  justify-content space-around

.clubs-search-sole__avatar
  display inline-block
  width 65px
  height 65px
  border-radius 3px
  box-shadow 0 0 1px $body-color-secondary

.clubs-search-sole__members
.clubs-search-sole__activity
  font-size $font-size-sm
  color $body-color-secondary

.clubs-search__results-title
  color $body-color-secondary
  margin-bottom 1rem
