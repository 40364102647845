@import "nodeca.core/client/common/_variables"


.user-settings-account__active-sessions
  margin-top 1.5rem

.user-settings-account__closed-sessions
  margin-top 1.5rem

.user-settings-account__session
  margin-bottom 1rem
  &:last-child
    margin-bottom 0

.user-settings-account__logout
  float right

.user-settings-account__logout-btn
  padding 0

.user-settings-account__device
  font-size $font-size-xs

.user-settings-account__ip
  color $body-color-secondary - 20%
  font-size $font-size-xs
