@import "nodeca.core/client/common/_variables"

cropper_border = 2px
cropper_resize_width = 15px
cropper_resize_offset = - cropper_resize_width * 0.5 - cropper_border * 0.5
cropper_resize_mark_width = 9px
cropper_resize_color = #ccc

.avatar-cropper
  display none
  position absolute
  left 0px
  top 0px

.avatar-cropper__move
  border cropper_border dotted cropper_resize_color
  position absolute
  top - cropper_border
  bottom - cropper_border
  right - cropper_border
  left - cropper_border
  cursor move
  background-color rgba(255, 255, 255, 0.25)

.avatar-cropper__left
  position absolute
  top 0px
  bottom 0px
  left cropper_resize_offset
  width cropper_resize_width
  cursor w-resize

.avatar-cropper__top
  position absolute
  top cropper_resize_offset
  left 0px
  right 0px
  height cropper_resize_width
  cursor n-resize

.avatar-cropper__bottom
  position absolute
  bottom cropper_resize_offset
  left 0px
  right 0px
  height cropper_resize_width
  cursor s-resize

.avatar-cropper__right
  position absolute
  top 0px
  bottom 0px
  right cropper_resize_offset
  width cropper_resize_width
  cursor e-resize

.avatar-cropper__top-left
.avatar-cropper__top-right
.avatar-cropper__bottom-right
.avatar-cropper__bottom-left
  position absolute
  width cropper_resize_width
  height cropper_resize_width

.avatar-cropper__top-left
  left cropper_resize_offset
  top cropper_resize_offset
  cursor nw-resize

.avatar-cropper__top-right
  right cropper_resize_offset
  top cropper_resize_offset
  cursor ne-resize

.avatar-cropper__bottom-right
  right cropper_resize_offset
  bottom cropper_resize_offset
  cursor se-resize

.avatar-cropper__bottom-left
  left cropper_resize_offset
  bottom cropper_resize_offset
  cursor sw-resize

// round markers

.avatar-cropper__mark
  box-shadow 0 0 0 1px black
  width cropper_resize_mark_width
  height cropper_resize_mark_width
  background-color cropper_resize_color + 50%
  border-radius cropper_resize_mark_width

.avatar-cropper__right
.avatar-cropper__left
  .avatar-cropper__mark
    position absolute
    left (cropper_resize_width - cropper_resize_mark_width) * 0.5
    top 50%
    margin-top - cropper_resize_mark_width * 0.5

.avatar-cropper__top
.avatar-cropper__bottom
  .avatar-cropper__mark
    position relative
    top (cropper_resize_width - cropper_resize_mark_width) * 0.5
    margin-left auto
    margin-right auto

.avatar-cropper__top-left
.avatar-cropper__top-right
.avatar-cropper__bottom-right
.avatar-cropper__bottom-left
  .avatar-cropper__mark
    position relative
    top (cropper_resize_width - cropper_resize_mark_width) * 0.5
    left (cropper_resize_width - cropper_resize_mark_width) * 0.5

// State change: use cursor from external container on cropper change

.avatar-dialog__m-crop-active
  .avatar-cropper__move
  .avatar-cropper__left
  .avatar-cropper__top
  .avatar-cropper__bottom
  .avatar-cropper__right
  .avatar-cropper__top-left
  .avatar-cropper__top-right
  .avatar-cropper__bottom-right
  .avatar-cropper__bottom-left
    cursor inherit
