@import "nodeca.core/client/common/_variables"

.rules-content
  & > h2
    font-size: 1.5rem
    margin-top 2rem
    margin-bottom 1rem
  a
    text-decoration underline

.rules-toc
  font-size .875rem
  margin-left 1rem
  color #666

  a
    //text-decoration none
    color inherit

.rules-anchor
  margin-left .25rem
  opacity .5