@import "nodeca.core/client/common/_variables"

.blogs-tag-list
  color $body-color-secondary
  font-size $font-size-sm
  margin-top .125rem

.blogs-tag-list__list
  margin 0
  padding 0
  list-style none
  margin-left 0.2rem
  display inline

.blogs-tag-list__item
  display inline

.blogs-tag-list__item + .blogs-tag-list__item
  &:before
    content ', '

.blogs-tag-list__link
  color $body-color-secondary
