.page-loading__content
  display none
.page-loading__progress
  display block

.page-loading__m-done
  .page-loading__content
    display block
  .page-loading__progress
    display none
