@import "nodeca.core/client/common/_variables"

$navbar-animation-duration = .1s

.navbar
  box-shadow 0 1px 3px rgba(0, 0, 0, 0.05)

// Add scroll on small height for expanded burger menu
// !!! Should NOT apply on collapsed state to keep dropdowns work
.navbar.navbar__m-menu
  max-height 100vh
  overflow auto


// Override toggler style
.navbar-dark .navbar-toggler
  background-image none
  border-color transparent
  color $navbar-dark-color
  padding .5rem
  margin-left .4rem

.navbar__right
.navbar__right-burger
  display flex
  margin-left auto
  flex-grow 0

.navbar__right-burger
  align-items center

.navbar-brand
  text-transform uppercase
  margin-right $spacer * 2

.navbar__level-up
  padding-left .8rem !important
  padding-right .8rem !important
  margin-left -.8rem
  margin-right .7rem // -.7rem + 2 rem - .5rem

//
// Alternate navigation. Shown when `navbar__m-secondary` modifier is added
//
.navbar-primary
  .navbar-inner
    opacity 1
    transition opacity $navbar-animation-duration linear

.navbar-alt
  flex-wrap nowrap
  top -($nav-panel-height)
  background-color transparent !important
  transition top .001s linear $navbar-animation-duration
  .navbar-inner
    opacity 0
    transition opacity $navbar-animation-duration linear

.navbar-alt .hidden-nav-alt
  display none !important

.page-head .hidden-page-head
  display none !important

.navbar__m-secondary
  &.navbar-primary .navbar-inner
    opacity 0
  &.navbar-alt
    top 0px
    transition top .001s linear
    .navbar-inner
      opacity 1

.navbar__m-menu.navbar__m-secondary
  &.navbar-primary .navbar-inner
    opacity 1
  &.navbar-alt .navbar-inner
    opacity 0

.navbar__title
  padding-right 1rem
  padding-left 1 rem
  shortable()

.navbar-alt .btn
  opacity .5

.navbar__hover-swap-container
  // Stretch to max free size
  flex 1 1 0%
  // Fix for inner shortable
  min-width 0
  position relative

  .navbar__hover-hide
    transition opacity $navbar-animation-duration
  .navbar__hover-show
    position absolute
    top 0
    opacity 0
    transition opacity $navbar-animation-duration

  &:hover
    .navbar__hover-hide
      opacity 0
    .navbar__hover-show
      opacity 1


@media (min-width: $grid-breakpoints.md)
  .navbar__right
    align-items center

  .navbar__right-burger
    display none

//
// Alternate header (progress bar instead of a brand).
// Shown when `navbar__m-progress` modifier is added
//
.navbar-progress
  display none

.navbar__m-progress
  .navbar-brand
    display none

  .navbar-progress
    display block
