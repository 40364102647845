@import "nodeca.core/client/common/_variables"

.user-tracker-item
  display flex
  align-items center
  padding .5rem 0

.user-tracker-item__content
  flex 1 1 0%
  padding .125rem 0
  position relative
  width auto
  // for ellipsis in second line on narrow screens
  min-width 0

.user-tracker-item__title
  position relative
  font-weight normal
  font-size $font-size-base
  //font-family $font-family-sans-serif-wide
  line-height 1.3
  margin-top 0
  margin-bottom 0

.user-tracker-item__title-link
  color $body-color

.user-tracker-item__microstat
  color $body-color-secondary
  font-size $font-size-xs
  shortable()
  & > a
    color inherit

.user-tracker-item__microstat-author
.user-tracker-item__microstat-time
  // middle dot after category in "category · author" or "category · time, author" on smaller screens
  &::before
    content '·'
    margin 0 .5em 0 .5em
    // inline-block is required to not show underline on hover
    display inline-block

.user-tracker-item__microstat-time
  display none
.user-tracker-item__stat-time
  display inline

@media (max-width: $grid-breakpoints.sm - 1)
  .user-tracker-item__microstat-time
    display inline
  .user-tracker-item__stat-time
    display none

  .user-tracker-item__microstat-author
    // comma between time and author in "category · time, author" on smaller screens
    &::before
      content ','
      margin 0 .5em 0 0
      display inline-block

// Enabled only for small screens
.user-tracker-item__overlay
  position absolute
  top 0
  left 0
  width 100%
  height 100%
  z-index 10

// Second column
.user-tracker-item__stat
  flex 0 0 0%
  min-width 6rem
  //font-size $font-size-sm
  color $body-color-secondary + 30%
  text-align right

.user-tracker-item__stat-link
  color $body-color-secondary + 30%

.user-tracker-item__new-marker
  display none
  new-marker()

.user-tracker-item__m-new
  .user-tracker-item__new-marker
    display inline-block

.user-tracker__separator
  align-items baseline
  font-size $font-size-xs
  color $body-color-secondary + 20%
  display flex
  gap .75rem

  &::before, &::after
    border-top 1px solid $decorator-split-color
    padding-bottom .25em
    flex 1 1 auto
    content ''

.user-tracker .content-tabs
  // compensate padding for 1st element,
  // real value should be 2rem (minus .5rem+.125rem)
  margin-bottom 1.375rem

.user-tracker__filter
  margin-bottom 2rem

  .btn
    font-size $font-size-sm
    padding: .4rem .8rem

  // nav-link adds padding which makes btn-square not square, try to fix it
  .nav-link.btn-square
    min-width 2.5rem

.user-tracker__no-items
  // compensate padding for 1st element, .5rem+.125rem
  padding-top .625rem

.user-tracker__loading-next
  padding 2rem
  font-size 4rem
  line-height 1.1em
  text-align center
  background-color $body-bg-secondary
  color $body-color-secondary + 50%
  border-radius 4px
  margin-bottom 2rem
