@import "nodeca.core/client/common/_variables"


.clubs-postlist__datediff
  margin-bottom 1.5rem
  padding .5rem 1rem
  font-size $font-size-sm
  color $body-color-secondary + 30%
  background-color $body-bg-secondary + 50%
  border-radius 2px
