@import "nodeca.core/client/common/_variables"

// aspect ratio 16:9 on wide screens
.user-settings-location__map-container
  width 100%
  padding-bottom 56.25%
  position relative
  margin-bottom 1rem

.user-settings-location__map
  position absolute
  top 0
  bottom 0
  left 0
  right 0

// override default leaflet cursor, so users
// can see that map can be clicked on
.user-settings-location__map.user-settings-location__map
  cursor pointer

.user-settings-location__submit
  position absolute
  top 10px
  right 10px
  z-index 1000

// aspect ratio 1:1 on narrow screens
@media (max-width: $grid-breakpoints.sm - 1)
  .user-settings-location__map-container
    padding-bottom 100%
