@import "nodeca.core/client/common/_variables"

//
// Indicator shown on the avatar in navbar
//
.navbar__m-unread-dialogs
  .navbar-auth__avatar-container
  .navbar-toggler // menu on mobile
    position relative

    &:after // .navbar-toggler:before is already busy
      position absolute
      width 16px
      height 16px
      border 3px solid $brand-primary
      border-radius 10px
      top -6px
      left 31px
      background-color #ffb700
      content ' '
      //box-shadow 0px 0px 1px 0 #808080

//
// Indicator shown near "dialogs" link in dropdown dialog
//
.navbar__m-unread-dialogs
  .navbar-auth__dropdown-dialogs:after
    width 6px
    height 6px
    border-radius 3px
    background-color #ffb700
    content ' '
    position relative
    right -12px
    margin-left -6px
    bottom 2px
    display inline-block
