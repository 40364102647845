@import "nodeca.core/client/common/_variables"

.market-section-buy .market-search-form-buy
  margin-bottom 2rem

.market-section-buy__subsections
  margin-bottom 2rem

.market-section-buy__head
  display flex

.market-section-buy__title
  flex 1 1 0%

.market-section-buy__subsection:before
  content '/ '
  font-family monospace

.market-section-buy__subsection.market-section-buy__linked:before
  content '→ '
  font-family monospace

.market-section-buy__subsection-title
  color $body-color

.market-section-buy__subsection-count
  color $body-color-secondary
  vertical-align sub
  font-size $font-size-xs

.market-section-buy__item-list
  margin 0
  padding 0
  list-style none

.market-section-buy__loading-prev
.market-section-buy__loading-next
  padding 2rem
  font-size 4rem
  line-height 1.1em
  text-align center
  background-color $body-bg-secondary
  color $body-color-secondary + 50%
  border-radius 4px
  margin-bottom 2rem
  margin-top 1rem
  clear both
