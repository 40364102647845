@import "nodeca.core/client/common/_variables"

.content-tabs
  display flex
  flex-wrap wrap
  gap .5rem
  list-style-type none
  padding 0
  margin-bottom 2rem

.content-tabs-link
  color #71858f
  background-color $body-bg-alt-secondary
  display flex
  font-size 0.875rem
  align-items center
  border-radius 3rem
  border 1px solid #e0e6ec
  padding .375rem .875rem

  &:hover, &:active
    text-decoration none

.content-tabs-link.active
  background-color $brand-primary
  color white

  &:hover, &:active
    color white

.content-tabs-counter
  color #8698a1
  margin-left .25rem
  align-self flex-start
  font-size .625rem

.content-tabs-link.active .content-tabs-counter
  color #dfdfdf
