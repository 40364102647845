@import "nodeca.core/client/common/_variables"

.market-item-buy__head
  display flex

.market-item-buy__title
  margin-bottom .5rem

.market-item-buy__title-toolbar
  flex 1 1 0%

.market-item-buy__edit
  font-size 1.25rem
  line-height 1.1em
  color $body-color-secondary
  vertical-align middle
  padding .125rem .5rem

.market-item-buy__closed-mark
  display none

.market-item-buy__m-closed
  .market-item-buy__closed-mark
    display inline-block

  .market-item-buy__contact
    display none

.market-item-buy__m-deleted
.market-item-buy__m-deleted-hard
  .market-item-buy__title-text
    text-decoration line-through

  .market-item-buy__contact
  .market-item-buy__bookmark
    display none

.market-item-buy__renew-btn
  float right

.market-item-buy__price
  font-size $font-size-lg
  margin-bottom .375rem

.market-item-buy__info
  color $body-color-secondary
  margin 0
  padding 0
  list-style none

.market-item-buy__info-item
  display inline

.market-item-buy__user-link
.market-item-buy__section-link
  color $body-color-secondary

.market-item-buy__info-item + .market-item-buy__info-item
  &:before
    content '|'
    margin-left .5rem
    margin-right .5rem
    opacity .7

.market-item-buy__details
  margin-bottom 1rem
  display table

.market-item-buy__details-item
  display table-row

.market-item-buy__details-key
  display table-cell
  color $body-color-secondary
  text-align right
  vertical-align top
  padding-right .75rem

.market-item-buy__details-value
  display table-cell
  vertical-align top

.market-item-buy__location-link
  // make link the same color as normal text
  color inherit

.market-item-buy__controls
  margin-bottom 2rem

.market-item-buy__controls-blk
  display flex
  align-items center
  margin 0 0 .5rem 0
  padding 0
  list-style none

.market-item-buy__control-item
  color $body-color-secondary
  display inline-block

.market-item-buy__action
  background-color transparent
  color $body-color-secondary

  &:hover
  &:focus
  &:active
    color $body-color-secondary - 20%
    background-color $body-bg-secondary

.market-item-buy__infraction .icon
  color $brand-danger
.market-item-buy__infraction-m-warning .icon
  color $brand-warning

.market-item-buy__bookmark-remove
  display none

// override .btn+.btn margin
.market-item-buy__bookmark-remove.market-item-buy__bookmark-remove
  margin 0

.market-item-buy__m-bookmarked
  .market-item-buy__bookmark-remove
    display inline-block
    opacity 1
    .icon
      color $brand-success
  .market-item-buy__bookmark-add
    display none

.market-item-buy__bookmarks-count
  font-size $font-size-xs
  color $body-color-secondary

.market-item-buy__bookmarks-count:after
  content attr(data-bm-count)
  margin-left .3em

.market-item-buy__bookmarks-count[data-bm-count="0"]:after
  display none

.market-item-buy__ts
  margin-right .75rem

.market-item-buy__views
  margin-left .75rem

//
// Responses block
//
.market-item-buy-responses
  margin-bottom 2rem

.market-item-buy-responses__header
  margin-bottom 1rem

.market-item-buy-responses__m-empty
  color $body-color-secondary

.market-item-buy-responses__item-list
  margin 0
  padding 0
  list-style none

.market-item-buy-response
  margin-bottom 0.5rem

.market-item-buy-response__link
  color $body-color + 30%
  font-size $font-size-sm

.market-item-buy-response__avatar
  display inline-block
  width 20px
  height 20px
  margin-right .5rem
  border-radius 2px
  box-shadow 0 0 1px $body-color-secondary

//
// Similar items block
//
.market-item-buy-similar
  margin-bottom 2rem

.market-item-buy-similar__item-list
  margin 0
  padding 0
  list-style none

.market-item-buy-similar-item
  display flex
  margin-top 1rem
  font-size $font-size-sm

.market-item-buy-similar-item__info
  margin-left 1rem

.market-item-buy-similar-item__title
  display block
  color $body-color + 30%

.market-item-buy-similar-item__price
  display block
  color $body-color-secondary


//
// Thumbnails
//

// 136x120 -> 51x45px
$thumb-similar-width  = 51px
$thumb-similar-height = $thumb-similar-width * '$$ N.config.market.uploads.resize.sm.max_height / N.config.market.uploads.resize.sm.max_width $$'
$corner-radius-similar = 2px

.market-item-offer-similar__attach-link
  border-radius $corner-radius-similar
  width $thumb-similar-width
  height $thumb-similar-height
  box-shadow 0 0 1px $body-color-secondary
  background-color $body-bg-secondary
  display flex
  align-items center
  justify-content center

.market-item-offer-similar__attach-image
  border-radius $corner-radius-similar
  max-width $thumb-similar-width
  max-height $thumb-similar-height
  width fit-content
  height fit-content
