@import "nodeca.core/client/common/_variables"

.blogs-sole-title-tag
  margin 0
  padding 0
  list-style none

.blogs-sole-title-tag__item
  display inline-block

  &:after
    margin 0 0.5rem
    content '/'

.blogs-sole-title-tag__avatar
  width 20px
  height 20px
  border-radius 3px
  box-shadow 0 0 1px $body-color-secondary
