@import "nodeca.core/client/common/_variables"

.breadcrumbs-container
  font-size $font-size-sm
  height $breadcrumbs-height
  //line-height $breadcrumbs-height
  background-color $breadcrumbs-bg
  margin-bottom 1.5rem
  color $breadcrumbs-color

.breadcrumbs
  line-height $breadcrumbs-height
  shortable()

.breadcrumbs__item
  &:before
    content '/'
    margin 0 .6em 0 .5em

  &:first-child:before
    content ''
    margin 0

  &:last-child:after
    content '/'
    margin 0 .6em 0 .5em

.breadcrumbs__m-nolink
  &:last-child:after
    content unset

.breadcrumbs__link
  color inherit
  text-decoration none

  &:hover
    color $breadcrumbs-hover-color
    text-decoration none

// Collapse list midle elements on narrow screens
//
@media (max-width: $grid-breakpoints.sm - 1)
  .breadcrumbs__item
    display none
    &:first-child
    &:last-child
      display inline

  .breadcrumbs__item + .breadcrumbs__item + .breadcrumbs__item:last-child
    &:before
      content '/ ... /'
