// Replace "Nodeca" text in navbar with logo.svg
//

@import "nodeca.core/client/common/_variables"

.navbar-brand
  background-image url('$$ JSON.stringify("data:image/svg+xml;base64," + Buffer.from(asset_body("rco-nodeca/rco_design/client/common/rco_logo/logo.svg")).toString("base64")) $$')
  background-repeat no-repeat
  background-size auto 22px
  background-position 0 9px
  opacity .5

  min-width 101px
  margin-right 1.7rem !important

  // hide text, but still make it available for screen readers and such;
  // avoid text appearing when user highlights it
  text-indent -1000px

  // needed for correct link outline
  overflow hidden
  vertical-align bottom // Fix for collapsed state
