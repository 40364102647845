@import "nodeca.core/client/common/_variables"

$avatar-size = 1.5rem

.breadcrumbs__icon
  margin-right .6em
  border-radius 2px

  height $avatar-size
  // center vertically
  vertical-align top
  //margin-top (($breadcrumbs-height - $avatar-size) / 2)
  margin-top .6875rem
