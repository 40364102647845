@import "nodeca.core/client/common/_variables"

.user-messages-list-reply
  border-bottom 1px solid $decorator-color
  margin -1rem 0 2rem
  padding-bottom .5rem

.user-messages-list__loading-prev
.user-messages-list__loading-next
  padding 2rem
  font-size 4rem
  line-height 1.1em
  text-align center
  background-color $body-bg-secondary
  color $body-color-secondary + 50%
  border-radius 4px
  margin-bottom 2rem
