 @import "nodeca.core/client/common/_variables"


.search-results__empty
  color $body-color-secondary

.search-results__loading-next
  padding 2rem
  font-size 4rem
  line-height 1.1em
  text-align center
  background-color $body-bg-secondary
  color $body-color-secondary + 50%
  border-radius 4px
  margin-bottom 2rem

//
// Single result styles
//
.search-result
  margin-bottom 1.5rem

.search-result__header
  border-bottom 1px solid $decorator-color
  padding-bottom .25rem
  margin-bottom .75rem

.search-result__title
  color $body-color !important
  //font-family $font-family-sans-serif-wide
  line-height 1.2
  margin-bottom .125rem
  display block

.search-result__stat-narrow
.search-result__stat
  font-size $font-size-xs
  color $body-color-secondary
  list-style none
  margin 0
  padding 0

.search-result__stat-item
  display inline-block

.search-result__stat-item
  &:after
    content '|'
    margin 0 .5rem
  &:last-child:after
    content ''

.search-result__stat-link
  color $body-color-secondary

//
// Modifications for results with posted content or snippets.
//

.search-result__m-with-content
  .search-result__title
    font-size 1.25rem
    color #6e99c4 !important

.search-result.search-result__m-with-content
  margin-bottom 2rem

//
// Content markup styles tweak
//
.search-result__content .markup
  .image
    max-width 50%

//
// Highlight search terms in output
//
.search-highlight
  background-color #e3f8e0
