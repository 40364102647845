@import "nodeca.core/client/common/_variables"

$progress-height = 1.5rem

.uploader-progress
  margin-bottom .5rem

.uploader-progress__container
  position relative
  .progress-bar
    height $progress-height

.uploader-progress__status
  display none
  position absolute
  font-size $font-size-xs
  top 0
  left 0
  right 0
  line-height $progress-height
  text-align center
  z-index 10

.uploader-progress__name
  font-size $font-size-sm
  shortable()
