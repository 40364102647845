@import "nodeca.core/client/common/_variables"


.member-block__blog-info
  list-style none
  margin 0
  padding 0
  font-size $font-size-sm

.member-block__blog-info-item
  margin-bottom .25em
  color $body-color-secondary
  shortable()

.member-block__blog-info-item-time
  //margin-right 1em
  display inline-block
  width 85px

.member-block__blog-info-item-deleted
.member-block__blog-info-item-deleted-hard
  .member-block__blog-info-item-title
    text-decoration line-through
