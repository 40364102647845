@import "nodeca.core/client/common/_variables"

.avatar-change
  position relative
  margin 0 auto
  text-align center


.avatar-change__info
  padding 2rem
  text-align center
  cursor pointer
  background-color $body-bg-alt-secondary
  border 1px solid transparent
  border-radius 3px
  shortable()

.avatar-change__canvas
  display none
  max-width 100%
  margin 0 auto
  border-radius 3px


.avatar-change.active
  .avatar-change__info
    border 1px dashed black
  .avatar-change__canvas
    border 1px dashed black


.avatar-preview
  position absolute
  right .5rem
  top .5rem
  width '$$ N.config.users.avatars.resize.md.width $$'px
  height '$$ N.config.users.avatars.resize.md.height $$'px
  display none
  box-shadow 0 0 0 1px white, 0 0 2px 1px black
  border-radius 2px

// Toggle visibility when image loaded

.avatar-change.avatar-change__m-loaded
  .avatar-change__canvas
  .avatar-preview
  .avatar-cropper
    display block
  .avatar-change__info
    display none

// Freeze cursor on cropper change

.avatar-dialog__m-cursor-n
  cursor n-resize
.avatar-dialog__m-cursor-e
  cursor e-resize
.avatar-dialog__m-cursor-w
  cursor w-resize
.avatar-dialog__m-cursor-s
  cursor s-resize
.avatar-dialog__m-cursor-nw
  cursor nw-resize
.avatar-dialog__m-cursor-ne
  cursor ne-resize
.avatar-dialog__m-cursor-sw
  cursor sw-resize
.avatar-dialog__m-cursor-se
  cursor se-resize
.avatar-dialog__m-cursor-move
  cursor move
