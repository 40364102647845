@import "nodeca.core/client/common/_variables"


.user-album-upload
  padding 1rem
  text-align center
  background-color $body-bg-alt-secondary
  margin-bottom 1.5rem
  border-radius 3px
  border 1px solid $body-bg-alt-secondary
  shortable()

  &.active
    border 1px dashed black

.user-album-root__no-files
  display none

.user-album-root.no-files
  .user-album-root__no-files
    display block
  .user-medialist
    display none

.user-album-root__loading-prev
.user-album-root__loading-next
  padding 2rem
  font-size 4rem
  line-height 1.1em
  text-align center
  background-color $body-bg-secondary
  color $body-color-secondary + 50%
  border-radius 4px
  margin-bottom 2rem

.user-medialist-item__select-overlay
  display none
  position absolute
  left 0
  right 0
  top 0
  bottom 0
  cursor pointer

.user-medialist-item__select-cb
  float right
  margin 0.2em

.user-medialist__m-selection .user-medialist-item__select-overlay
  display block
