@import "nodeca.core/client/common/_variables"

$avatar-size            = 60px // + border
$avatar-size-sm         = 35px
$avatar-self-size       = 20px

.dialog-list-item
  display flex
  margin-bottom 2rem

// Avatar + tab
.dialog-list-item__aside
  flex 0 0 0%
  padding-top .125rem

.dialog-list-item__aside-inner
  display block
  position relative
  &:after
    display none
    position absolute
    content ''
    top -.5rem
    right -.5rem
    width 1rem
    height 1rem
    border-radius 1rem
    background-color $brand-primary
    border 2px solid white

.dialog-list-item__avatar-image
  box-sizing border-box
  border 1px solid $decorator-color
  width $avatar-size
  height $avatar-size
  border-radius 3px
  vertical-align top

.dialog-list-item__m-unread
  .dialog-list-item__aside-inner:after
    display block

.dialog-list-item__aside-inline
  flex 0 0 0%
  margin-right .5rem
  display none

  .dialog-list-item__avatar-image
    width $avatar-size-sm
    height $avatar-size-sm
    border-radius 2px 2px

// Content
.dialog-list-item__content
  flex 1 1 0%
  padding-left .75rem
  min-width 0

.dialog-list-item__header
  display flex
  align-items center

.dialog-list-item__info
  flex 1 1 100%
  min-width 0
  display flex
  line-height 1.125rem

.dialog-list-item__talk-with
  flex 1 1 100%
  display block
  shortable()

.dialog-list-item__date
  flex 1 1 0%
  font-size $font-size-xs
  white-space nowrap
  padding-left 1.5rem
  color $body-color-secondary + 20%

.dialog-list-item__controls
  padding-left .5rem
  height 1rem
  margin-right -.5rem
  margin-top -.95rem

.dialog-list-item__avatar-self
  width $avatar-self-size
  height $avatar-self-size
  margin-right .375rem
  border-radius 2px

.dialog-list-item__preview
  display flex

.dialog-list-item__preview-aside
  flex 0 0 0%
  display none

a.dialog-list-item__preview-text
  flex 1 1 0%
  display block
  //font-family $font-family-sans-serif-wide
  font-size $font-size-sm
  color $body-color-secondary - 10%
  text-decoration none
  margin-top .125rem

.dialog-list-item__m-outgoing
  .dialog-list-item__preview
    padding-top .25rem
  .dialog-list-item__preview-aside
    display block
  a.dialog-list-item__preview-text
    background-color $body-bg-secondary - 3%
    border-radius 4px
    padding .5rem

.dialog-list-item__delete
  color $body-color-secondary + 60%


@media (max-width: $grid-breakpoints.sm - 1)
  .dialog-list-item__content
    padding-left 0
  .dialog-list-item__aside
    display none
  .dialog-list-item__aside-inline
    display block
  .dialog-list-item__info
    flex-direction column
  .dialog-list-item__date
    padding-left 0
  .dialog-list-item__avatar-self
    margin-right .5rem
  .dialog-list-item__m-outgoing
    .dialog-list-item__preview
      padding-left .925rem
