/**
 *= require ./src/css/nodeca-codes
 **/

@font-face {
  font-family: 'nodeca';
  src: url(/assets/nodeca-2d8d40f429a02da6c4efff910753bca0.woff2) format('woff2'),
       url(/assets/nodeca-99f29f26f4746e0644f39bb4d993aa8c.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

.icon:before {
  font-family: "nodeca";
  speak: none;
  /*text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3);*/

  display: inline-block;
  text-align: center;
  line-height: 1em;
  position: relative;

  font-style: normal !important;
  font-weight: normal !important;
}

.icon-space-after:before {
  margin-right: .3em;
}

.icon-space-before {
  margin-left: .3em;
}

.icon-dimmed:before {
  opacity: 0.7;
}

.icon-lg:before {
  font-size: 120%;
  top: 1px;
}

.icon-sm:before {
  font-size: .8em;
  top: -.05em;
}
