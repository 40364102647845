@import "nodeca.core/client/common/_variables"

$sidebar-width = 102px // 100 + border

#ucard-popover
  cursor default
  background-color white
  display none
  position absolute
  z-index 900

  width 400px
  min-height 180px
  border 1px solid $decorator-color
  border-radius 4px
  box-shadow 0 0 1rem $decorator-color

  margin-left .5rem
  padding 1rem

  &.ucard-popover__m-bottom
    margin-top .5rem
    margin-left 0

.ucard-sidebar
  float left
  width $sidebar-width

.ucard-avatar
  display block
  border 1px solid $decorator-color
  border-radius 4px

.ucard-avatar__image
  width 100%
  border-radius 4px

.ucard__controls
  padding 0
  margin .5rem 0 0 0

.ucard__controls-item
  list-style none
  margin-top .4rem

.ucard-info
  overflow hidden
  padding-left 1rem

.ucard-info__head
  shortable()

  font-size 1.5rem
  line-height 1.2em
  margin-top -.2rem
  margin-bottom .5rem

.ucard-info__head-link
  &:hover
    text-decoration none


.ucard-info__dl
  font-size $font-size-sm
  line-height 1.1em
  margin 0

.ucard-info__dt
  float left
  clear left
  width 100px
  font-weight normal
  color $body-color-secondary
  &:after
    content ':'

.ucard-info__dd
  margin: 0 0 .5rem 110px
  &:last-child
    margin-bottom 0
