@import "nodeca.core/client/common/_variables"


.user-settings-ignore
  table-layout fixed
  margin-bottom 0
  td
    border-top 0 !important

.user-settings-ignore__avatar
  width 16px
  height 16px
  margin 0px 3px
  border-radius 2px

.user-settings-ignore__user
  shortable()
  width 250px
  padding-left 0 !important

.user-settings-ignore__expire
  width 130px
  text-align right

.user-settings-ignore__controls
  width 30px
  text-align right
  padding-right 0 !important


@media (max-width: $grid-breakpoints.md - 1)
  .user-settings-ignore__reason
    display none
  .user-settings-ignore__user
    width auto

@media (max-width: $grid-breakpoints.sm - 1)
  .user-settings-ignore__expire
    display none
