//- Toolbar with buttons, containing major page actions

.page-actions
  white-space nowrap

.page-actions > .btn
  margin-left .4rem

.page-actions__dropdown
  position relative
  display inline-block

.page-actions > .btn + .page-actions__dropdown
  margin-left .4rem

// Additional containers for each button. Used only when switchable
// layout needed (on member page, for example)
.page-actions__item
  display inline-block

.page-actions__item + .page-actions__item
  margin-left .4rem
