@import "nodeca.core/client/common/_variables"

.votes-popover
  // Should be under toolbar
  z-index 900 !important

.votes-popover-content
  margin 0

.votes-popover__dt
  float left
  font-weight normal

.votes-popover__dd
  margin-left 2em
  &:last-child
    margin-bottom .125rem

.votes-popover__list
  padding 0
  margin 0

.votes-popover__list-item
  list-style none
  display inline-block

  &:after
    margin-right .3em
    content ","

  &:last-child:after
    margin-right 0
    content ""
