@import "nodeca.core/client/common/_variables"

.blogs-index__head
  display flex

.blogs-index__title
  flex 1 1 0%
  color lighten($brand-primary, 67%)
  //font-size $font-size-lg

.blogs-index__loading-prev
.blogs-index__loading-next
  padding 2rem
  font-size 4rem
  line-height 1.1em
  text-align center
  background-color $body-bg-secondary
  color $body-color-secondary + 50%
  border-radius 4px
  margin-bottom 2rem

.blogs-index
  .blog-entry__m-deleted
  .blog-entry__m-hb
  .blog-entry__m-deleted-hard
  .blog-entry__m-ignored
    background-color $deleted-bg

  .blog-entry__title
    font-size $h4-font-size
