@import "nodeca.core/client/common/_variables"

// this rule stretch background on short pages
//html
//body
//  height 100%
:root
  --page-max-width $page-max-width

body
  min-height 100vh

html
  scroll-padding-top $nav-panel-height + 20px

body
  padding-top $nav-panel-height
  min-width 200px
  // Always show vertical scroll, to avoid small horizontal
  // jitter on change between short and long pages.
  // (!) Disabled because Chrome scroll page to top after fullscreen video.
  //overflow-y scroll

  display flex
  flex-direction column

.layout__container
  max-width var(--page-max-width)
  width 100%

.layout__container.space-top
  margin-top 2rem

#content
  flex 1 1 100%

// Debug output block at page bottom
//
.debug
  display none
  position fixed
  left 0
  right 0
  bottom 0
  top 200px
  overflow auto
  padding-top 1rem
  font-size $font-size-xs
  color #888
  background-color #fbfbf8
  border-top 1px solid #eee

.debug-on .debug
  display block


// "No JS" message styles
//
.nojs-alert
.nocookies-alert
  display none
  left 50%
  width 500px
  margin-left -250px
  text-align center
  position fixed
  top 90px
  z-index: 1000

.nojs-alert
  display block

.no-cookies .nocookies
  display block

.site-offline-msg
  display none
  position fixed
  bottom 0
  left 1rem
  right 1rem

.site-offline .site-offline-msg
  display block
