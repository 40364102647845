@import "nodeca.core/client/common/_variables"

$avatar-size  = 2.375rem

.mod-notes-item
  margin-bottom 2rem

.mod-notes-item__info
  display flex
  align-items flex-end
  color $body-color-secondary
  font-size $font-size-xs
  line-height 1.1em
  margin-bottom .5rem

.mod-notes-item__info-aside
  float left

.mod-notes-item__avatar-image
  width $avatar-size
  height $avatar-size
  border-radius 2px
  box-shadow: 0 0 1px $body-color-secondary

.mod-notes-item__info-data
  flex 1 1 100%
  border-bottom 1px solid $decorator-color
  margin-left 0.5rem

.mod-notes-item__author
  font-size $font-size-sm
  line-height 1.1em
  color $body-color-secondary - 10%
  shortable()
  margin-bottom .25rem
.mod-notes-item__author-link
  color $body-color-secondary - 10%

.mod-notes-item__ts
  margin-bottom .25rem

.mod-notes-item__controls
  border-bottom 1px solid $decorator-color
.mod-notes-item__controls-inner
  white-space nowrap
  margin-right -.25rem

.mod-notes-item__controls-btn
  opacity .5
  transition opacity .2s ease
  margin-left 0 !important

.mod-notes-item__info:hover .mod-notes-item__controls-btn
  opacity 1
