@import "nodeca.core/client/common/_variables"

.market-search-form-buy
  background-color $body-bg-alt-secondary
  border-radius .25rem

.market-search-form-buy__input-container
  padding 1rem 1.25rem .5rem
  width 100%

.market-search-form-buy__meta
  display flex
  align-items baseline
  margin-top .25rem
  min-height 1em

.market-search-form-buy__meta-item
  font-size $font-size-sm
  color $body-color-secondary

.market-search-form-buy__meta-item + .market-search-form-buy__meta-item
  margin-left 1rem

.market-search-form-buy__submit
  background-color white
  color $body-color

.market-search-form-buy__options
  width 100%

.market-search-form-buy__options-inner
  border-top 1px solid ($body-color-secondary + 60%)
  padding 1rem 1.25rem

.market-search-form-buy__options-inner-row
  display flex

.market-search-form-buy__options-inner-row-check
  margin-right 1rem

.market-search-form-buy__options-inner-row + .market-search-form-buy__options-inner-row
  margin-top 0.5rem

.market-search-form-buy__options-control
  display flex
  align-items baseline

.market-search-form-buy__options-control-label
  margin-right 0.5em

.market-search-form-buy__price-currency
  max-width 4em

.market-search-form-buy__options-price-dash
  margin 0 0.5em

@media (min-width: $grid-breakpoints.sm)
  .market-search-form-buy__price
    max-width 40%
