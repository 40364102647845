// Forum sections list. Used on forum `index` & forum `section` page
//

@import "nodeca.core/client/common/_variables"


.forum-section
  display flex
  align-items center
  // min-height for thin row
  min-height 3.5rem
  // keep some space for fat rows (with subsections & descriptions)
  padding .7rem 0

.forum-section__stats
  flex 0 0 0%
  min-width 6rem
  font-size $font-size-sm
  color $body-color-secondary + 30%
  text-align right
  .icon
    visibility hidden

.forum-section:first-child > .forum-section__stats .icon
  visibility visible

//
// first (main) column
//

triangle-size = .25rem

.forum-section__summary
  flex 1 1 0%
  padding-left 1.25rem
  position relative
  width auto
  // triangle
  &:before
    position absolute
    left 0
    top 50%
    margin-top -(triangle-size)
    width 0
    height 0
    border-style solid
    border-width triangle-size 0 triangle-size (triangle-size * 1.4)
    border-color transparent transparent transparent $decorator-aside-color
    content ''

.forum-section__m-unread
  .forum-section__summary:before
    border-color transparent transparent transparent $brand-primary

.forum-section__title
  position relative
  font-weight normal
  font-size $font-size-base
  // font-family $font-family-sans-serif-wide
  line-height 1.3
  margin-top 0
  margin-bottom 0

.forum-section__title-link
  color $body-color
  display inline-block
  width 100%

// -- subforums list
.forum-section__subforums
  position relative
  // font-family $font-family-sans-serif-wide
  font-size $font-size-xs
  margin 0 0 0 1.2em
  padding 0
  color $body-color + 30%
  &:before
    position absolute
    left -1em
    line-height inherit
    color $brand-success

.forum-section__subforum-item
  display inline-block
  &:after
    content: ",";
    margin-right: 0.5em;
  &:last-child:after
    content: "";

.forum-section__subforum-link
  color $body-color + 30%

.forum-section__microstat
  font-size $font-size-xs
  color $body-color-secondary
  shortable()

// Enabled only for small screens
.forum-section__overlay
  position absolute
  top 0
  left 0
  width 100%
  height 100%
  z-index 10


@keyframes forum-section-flash-animation {
  from  { background: #bbeeff;  }
  to    { background: none; }
}

.forum-section.forum-section__m-highlight
  animation forum-section-flash-animation 3s
  border-left 1px solid #428bca
