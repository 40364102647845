@import "nodeca.core/client/common/_variables"

// Hide default caret from .dropdown-toggle
.navbar-auth__dropdown-toggle:after
  display none

.navbar-auth__avatar
  height 2.5rem
  width 2.5rem
  border-radius 3px
  border 1px solid ($brand-primary + 20%)

.navbar-auth__dropdown-toggle
  padding-right 0 !important
.navbar-guest
  padding-right 0 !important

//
// Tweak top/bottom padding for mobile and desktop toolbars
//
.navbar-auth__dropdown-toggle
  padding-bottom 0 !important

@media (min-width: $grid-breakpoints.md)
  .navbar-auth__dropdown-toggle
    padding-top 0 !important
    padding-left .4rem !important
