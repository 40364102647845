@import "nodeca.core/client/common/_variables"

//
// Layout
//

$avatar-wide = 150px
$avatar-medium = 100px
$avatar-narrow = 70px


.user-member-page__m-deleted .page-head__title
  text-decoration line-through 4px $brand-danger


.member-head
  margin-top 2rem
  margin-bottom 1rem
  .page-head
    margin-top 0
    margin-bottom .5rem

.member-head__aside
  width $avatar-wide
  float left
.member-head__content
  min-height $avatar-wide
  padding-left $avatar-wide + 20px

.member-head__m-guest
  .member-head__aside
    width $avatar-medium
  .member-head__content
    min-height $avatar-medium
    padding-left $avatar-medium + 20px


.member-blocks
  // To show shadows in { overflow: hidden }
  padding-left 2px
  margin-left -2px
  margin-top 2rem
  overflow hidden

//
//
//

.member-menu
  .btn
    margin-left 0
    margin-right .4rem
  .btn-sm
    margin-left 0
    margin-right .3rem

.member-menu__dropdown
  display inline-block
  position relative

//
// Avatar styles
//

.member-head__m-avatar-exists
  .member-avatar__delete
    display block

.member-avatar
  position relative
.member-avatar__image
  width 100%
  border-radius 0.1875rem
  box-shadow 0 0 0.125rem $decorator-color
.member-avatar__link
  display block
.member-avatar__caption
  display block
  position absolute
  bottom 0
  width 100%
  text-align center
  background-color rgba($body-color, 0.6)
  color $body-color-secondary
  font-size $font-size-xs
  transition background-color .2s ease
  border-radius 0 0 0.1875rem 0.1875rem
  padding 0 .5em
  shortable()

.member-avatar__delete
  display none
  position absolute
  top .25rem
  right .25rem
  opacity .4
  transition opacity .2s ease

.member-avatar:hover
  .member-avatar__caption
    background-color $body-color
  .member-avatar__delete
    opacity 1

//
// Content block in profile
//

.member-block
  margin-bottom 2rem

.member-block__header
  font-size 1.25rem
  border-bottom 0.0625rem solid $decorator-color
  margin-bottom 1rem
  //color $body-color + 20%

.member-block__header-text > .btn
  vertical-align text-bottom

.member-block__header-arrow
  margin-left .3em
  margin-right .25em
  opacity .8
  font-size:  1.125rem

.member-block__header-link
  color inherit
  &:hover
    text-decoration none

.member-block__header-counter
  //font-size 0.75rem
  font-size 0.625rem
  color $body-color-secondary
  vertical-align text-bottom

.member-block__header-collapser
  float right
  margin-left .5em
  margin-right .2em
  &:focus
    outline none


.member-blocks__col-left .member-block
  padding-right .75rem
.member-blocks__col-right .member-block
  padding-left .75rem

@media (max-width: $grid-breakpoints.md - 1)
  .member-blocks__col-left .member-block
    padding-right 0
  .member-blocks__col-right .member-block
    padding-left 0

//
// Collapse toggler states
//

.member-block__header-collapser
  .icon-expand
    display none

  .icon-collapse
    opacity .3
    transition opacity .3s ease
  &:hover
    .icon-collapse
      opacity 1

  &.collapsed
    .icon-expand
      display inline
    .icon-collapse
      display none


.member-block__image-list
  padding 0
  margin 1rem 0 0
  white-space nowrap
  position relative
  &:after
    content ""
    display block
    position absolute
    right 0
    top 0
    bottom 0
    width 2rem
    background linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%)

.member-block__image-list-item
  display inline-block
  list-style none
  margin-right 1rem

.member__ignored
  color $body-color-secondary

// Force blocks expand on wide screens
@media (min-width: $grid-breakpoints.md)
  .member-block__inner
    display block !important
    height auto !important
  .member-block__header-collapser
    display none


@media (max-width: 650px - 1)
  .member-head
    .member-head__aside
      width $avatar-medium
    .member-head__content
      min-height $avatar-medium
      padding-left $avatar-medium + 16px


@media (max-width: $grid-breakpoints.sm - 1)
  .member-head
    .member-head__aside
      width $avatar-narrow
    .member-head__content
      min-height $avatar-narrow
      padding-left $avatar-narrow + 16px
    .page-head__title
      font-size 1.25rem

  //.member-block__header
  //  font-size 1.25rem
  //.member-block__header-counter
  //  font-size 0.625rem // 10px


.member-actions-xs
  display flex
  margin-bottom 2rem
  .page-actions__item
    flex 1 1 100%
  .btn
    width 100%
  .dropdown-menu
    min-width 100%
