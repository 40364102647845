@import "nodeca.core/client/common/_variables"

.blogs-entry-title
  margin 0
  padding 0
  list-style none

.blogs-entry-title__item
  display inline-block
  margin-right 0.5rem

.blogs-entry-title__avatar
  width 20px
  height 20px
  border-radius 3px
  box-shadow 0 0 1px $body-color-secondary
