@import "nodeca.core/client/common/_variables"

.market-search-form-wish
  background-color $body-bg-alt-secondary
  border-radius .25rem

.market-search-form-wish__input-container
  padding 1rem 1.25rem .5rem
  width 100%

.market-search-form-wish__meta
  display flex
  align-items baseline
  margin-top .25rem
  min-height 1em

.market-search-form-wish__meta-item
  font-size $font-size-sm
  color $body-color-secondary

.market-search-form-wish__meta-item + .market-search-form-wish__meta-item
  margin-left 1rem

.market-search-form-wish__submit
  background-color white
  color $body-color

.market-search-form-wish__options
  width 100%

.market-search-form-wish__options-inner
  border-top 1px solid ($body-color-secondary + 60%)
  padding 1rem 1.25rem

.market-search-form-wish__options-inner-row + .market-search-form-wish__options-inner-row
  margin-top 0.5rem

.market-search-form-wish__options-control
  display flex
  align-items baseline

.market-search-form-wish__options-control-label
  margin-right 0.5em
