@import "nodeca.core/client/common/_variables"

// Override breadcrumbs layout to add inline button
.market-index-wish .breadcrumbs
  display inline

.market-index-wish__head
  display flex

.market-index-wish__title
  flex 1 1 0%

.market-index-wish__switch-type
  text-align center
  font-size $font-size-sm
  border-bottom 1px solid $decorator-color
  padding 0.625rem 0
  margin-bottom 1rem

.market-index-wish__switch-type-link
  color $brand-primary

$section-spacing = 1rem

.market-index-wish__categories
  display flex
  flex-wrap wrap
  margin 0 $section-spacing * -1

.market-index-wish__section
  padding 0 $section-spacing
  width 33.33%
  margin-bottom 2rem

.market-index-wish__section-title
  font-size $h5-font-size
  line-height 1.1em
  margin-bottom .75rem

.market-index-wish__section-link
  color $body-color

.market-index-wish__subsection
  line-height 1.1em
  margin-bottom .5rem

.market-index-wish__subsection-link
  color $body-color-secondary


@media (max-width: $grid-breakpoints.md - 1)
  .market-index-wish__section
    width 50%


@media (max-width: $grid-breakpoints.sm - 1)
  .market-index-wish__section
    width 100%
    margin-bottom 1.5rem
  .market-index-wish__section-title
    margin-bottom 0
  .market-index-wish__subsection
    display none
